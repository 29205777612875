import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout justifyCenter title="Page not found">
    <div className="center">
      <h1>404 💫</h1>
      <p>
        Oops! Looks like this page doesn&apos;t exist on Swarmia.
        <br />
        Let us show you the way back home.
      </p>
      <Link to="/">
        <button>Go home</button>
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
